/* eslint-disable react/no-array-index-key */
import React from "react";
import { Col, Layout, Row, Space } from "antd";
import appStyle from "./styles/app";
import generalStyle from "./styles/general";

const { Footer } = Layout;

// INTERFACE
interface ISocialIcon {
  backgroundOffset: number;
  link: string;
}

interface IFooterLink {
  label: string;
  link?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

// SOCIAL ICONS
const socialIcons = [
  {
    backgroundOffset: 0,
    link: "https://www.facebook.com/songtradr",
  },
  {
    backgroundOffset: -32,
    link: "https://twitter.com/songtradr",
  },
  {
    backgroundOffset: -96,
    link: "https://www.linkedin.com/company/6392408/admin/",
  },
  {
    backgroundOffset: -128,
    link: "https://www.instagram.com/songtradr/",
  },
  {
    backgroundOffset: -64,
    link: "https://www.youtube.com/songtradr",
  },
] as ISocialIcon[];

// FOOTER LINKS
const footerLinks = [
  {
    label: "ABOUT SONGTRADR",
    link: "https://www.songtradr.com/user/about",
  },
  {
    label: "HELP",
    link: "https://support.songtradr.com",
  },
  {
    label: "BLOG",
    link: "https://themusicsolution.songtradr.com",
  },
  // {
  //   label: "CONTACT US",
  //   onClick: () => {
  //     console.log("Coming Soon!");
  //   },
  // },
  {
    label: "LEGAL",
    link: "https://www.songtradr.com/user/legals",
  },
  // {
  //   label: "REPORT VIOLATION",
  //   onClick: () => {
  //     console.log("Coming Soon!");
  //   },
  // },
] as IFooterLink[];

// COMPONENT
export default function AppFooter(): React.ReactElement {
  // RENDER
  return (
    <Footer css={appStyle.footer.wrapper}>
      <Row>
        <Col span={16} offset={4}>
          <Space css={[generalStyle.fullWidth, appStyle.footer.socialIcons]}>
            {socialIcons.map((icon: ISocialIcon, index: number) => (
              <a
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
                css={appStyle.footer.socialIcon}
                key={`__footerIcon_${index}`}
              >
                <div
                  css={
                    /* stylelint-disable value-keyword-case */ {
                      backgroundPositionX: icon.backgroundOffset,
                    } /* stylelint-enable value-keyword-case */
                  }
                />
              </a>
            ))}
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={16} offset={4}>
          <Space css={[generalStyle.fullWidth, appStyle.footer.footerLinks]}>
            {footerLinks.map((link: IFooterLink, index: number) => (
              <a
                href={link.link ?? "#"}
                onClick={link.onClick}
                key={`__footerLink_${index}`}
              >
                {link.label}
              </a>
            ))}
          </Space>
        </Col>
      </Row>
      <div css={appStyle.footer.copyright}>
        ALL RIGHTS RESERVED | &copy;{new Date().getFullYear()} SONGTRADR, INC
      </div>
    </Footer>
  );
}
