import { css } from "@emotion/react";
import colors from "../../colors";

// GENERAL STYLES FOR ALL AUTO TOOLS
const generalStyle = {
  HeaderTitle: css`
    /* font-size: 20px;
    font-weight: 400;
    margin-bottom: 7px; */
  `,
  HeaderSubTitle: css`
    margin-top: -6px;
    font-weight: 100;
    font-size: 13px;
  `,
  spaceRight: css`
    justify-content: flex-end;
    width: 100%;
  `,
  link: css`
    color: inherit !important;
    text-decoration: none;
    :hover {
      text-decoration: underline !important;
    }
  `,
  tableActionButton: css`
    padding-right: 0;
  `,
  inputSuffixButton: css`
    margin: -12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `,
  fullWidth: css`
    width: 100%;
  `,
  button: {
    green: css`
      /* stylelint-disable function-name-case */
      background: ${colors.darkgreen.full};
      border-color: ${colors.darkgreen.full};
      :hover {
        background: ${colors.LightenDarkenColor(colors.darkgreen.full, -10)};
        border-color: ${colors.LightenDarkenColor(colors.darkgreen.full, -10)};
      }
      :focus {
        background: ${colors.darkgreen.full};
        border-color: ${colors.darkgreen.full};
      }
    `,
    spotifyGreen: css`
      background: #1db954;
      border-color: #1db954;
      :hover {
        background: ${colors.LightenDarkenColor("#1db954", -10)};
        border-color: ${colors.LightenDarkenColor("#1db954", -10)};
      }
      :focus {
        background: #1db954;
        border-color: #1db954;
      }
    `,
    appleMusicRed: css`
      background: #fc3c44;
      border-color: #fc3c44;
      color: white;
      :hover {
        background: ${colors.LightenDarkenColor("#fc3c44", -10)};
        border-color: ${colors.LightenDarkenColor("#fc3c44", -10)};
        color: white;
      }
      :focus {
        background: #fc3c44;
        border-color: #fc3c44;
        color: white;
      }
    `,
    dark: css`
      background: ${colors.gray.full};
      border-color: ${colors.gray.full};
      :hover {
        background: ${colors.LightenDarkenColor(colors.gray.full, -10)};
        border-color: ${colors.LightenDarkenColor(colors.gray.full, -10)};
      }
      :focus {
        background: ${colors.gray.full};
        border-color: ${colors.gray.full};
      }
    `,
    extraDark: css`
      background: ${colors.LightenDarkenColor(colors.gray.full, -30)};
      border-color: ${colors.LightenDarkenColor(colors.gray.full, -30)};
      :hover {
        background: ${colors.LightenDarkenColor(colors.gray.full, -10)};
        border-color: ${colors.LightenDarkenColor(colors.gray.full, -10)};
      }
      :focus {
        background: ${colors.LightenDarkenColor(colors.gray.full, -30)};
        border-color: ${colors.LightenDarkenColor(colors.gray.full, -30)};
      }
      :disabled {
        color: ${colors.LightenDarkenColor(colors.gray.full, +10)};
        background: ${colors.LightenDarkenColor(colors.gray.full, -30)};
        border-color: ${colors.LightenDarkenColor(colors.gray.full, -30)};
      }
    `,
    orange: css`
      background: ${colors.orange.full};
      border-color: ${colors.orange.full};
      :hover {
        background: ${colors.LightenDarkenColor(colors.orange.full, -10)};
        border-color: ${colors.LightenDarkenColor(colors.orange.full, -10)};
      }
      :focus {
        background: ${colors.orange.full};
        border-color: ${colors.orange.full};
      }
    `,
    yellow: css`
      background: ${colors.yellow.full};
      border-color: ${colors.yellow.full};
      color: rgba(0, 0, 0, 0.85);
      text-shadow: none;
      :hover {
        background: ${colors.LightenDarkenColor(colors.yellow.full, -5)};
        border-color: ${colors.LightenDarkenColor(colors.yellow.full, -5)};
        color: rgba(0, 0, 0, 0.85);
        text-shadow: none;
      }
      :focus {
        background: ${colors.yellow.full};
        border-color: ${colors.yellow.full};
        color: rgba(0, 0, 0, 0.85);
        text-shadow: none;
      }
    `,
    red: css`
      background: ${colors.red.full};
      border-color: ${colors.red.full};
      :hover {
        background: ${colors.LightenDarkenColor(colors.red.full, -10)};
        border-color: ${colors.LightenDarkenColor(colors.red.full, -10)};
      }
      :focus {
        background: ${colors.red.full};
        border-color: ${colors.red.full};
      }
      /* stylelint-enable function-name-case */
    `,
  },
  form: css`
    .ant-form-item-label {
      font-weight: 500;
    }
  `,
  align: {
    center: css`
      justify-content: center;
    `,
  },
  drawerRowSaveButton: css`
    min-width: 100px;
    .ant-typography {
      color: inherit !important;
    }
  `,
  drawerRow: css`
    align-items: center;
  `,
  drawerWithHeaderActions: css`
    .ant-drawer-content-wrapper {
      width: 79vw !important;
    }
    @media (min-width: 1600px) {
      .ant-drawer-content-wrapper {
        width: 65vw !important;
      }
    }
    .ant-drawer-header {
      padding: 0 24px;
      height: 57px;
      display: flex;
      align-items: center;

      .ant-drawer-title {
        flex: 1;
      }
    }
  `,
  marginBottomRow: css`
    margin-bottom: 15px;
  `,
};

export default generalStyle;
