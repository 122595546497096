/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
const colors = {
  white: "#ffffff",
  black: "#000000",
  transparent: "transparent",
  gray: {
    full: "#2c3237",
    f80: "#575b60",
    f60: "#818488",
    f40: "#abadaf",
    f20: "#d5d6d7",
    f10: "#eaeaeb",
    f5: "#f4f5f5",
    a80: "rgba(45,50,56,0.8)",
    a60: "rgba(45,50,56,0.6)",
    a40: "rgba(45,50,56,0.4)",
    a20: "rgba(45,50,56,0.2)",
    a10: "rgba(45,50,56,0.1)",
    a5: "rgba(44,50,55,0.05)",
    rowBackground: "rgb(147,150,155, 0.11)",
  },
  blue: {
    full: "#0099ff",
    f80: "#33adff",
    f60: "#66c2ff",
    f40: "#99d6ff",
    f20: "#ccebff",
    a80: "rgba(0,153,255,0.8)",
    a60: "rgba(0,153,255,0.6)",
    a40: "rgba(0,153,255,0.4)",
    a20: "rgba(0,153,255,0.2)",
  },
  orange: {
    full: "#ce4519",
    f80: "#d86a47",
    f60: "#e28f75",
    f40: "#ebb5a3",
    f20: "#f5dad1",
    a80: "rgba(206,69,25,0.8)",
    a60: "rgba(206,69,25,0.6)",
    a40: "rgba(206,69,25,0.4)",
    a20: "rgba(206,69,25,0.2)",
  },
  green: {
    full: "#00cc00",
    f80: "#33d633",
    f60: "#66e066",
    f40: "#99eb99",
    f20: "#ccf5cc",
    a80: "rgba(0,204,0,0.8)",
    a60: "rgba(0,204,0,0.6)",
    a40: "rgba(0,204,0,0.4)",
    a20: "rgba(0,204,0,0.2)",
  },
  darkgreen: {
    full: "#42a942",
  },
  red: {
    full: "#cc0000",
    f80: "#d63333",
    f60: "#e06666",
    f40: "#eb9999",
    f20: "#f5cccc",
    a80: "rgba(204,0,0,0.8)",
    a60: "rgba(204,0,0,0.6)",
    a40: "rgba(204,0,0,0.4)",
    a20: "rgba(204,0,0,0.2)",
  },
  yellow: {
    full: "#ffcc00",
    f80: "#ffd633",
    f60: "#ffe066",
    f40: "#ffeb99",
    f20: "#fff5cc",
    a80: "rgba(255,204,0,0.8)",
    a60: "rgba(255,204,0,0.6)",
    a40: "rgba(255,204,0,0.4)",
    a20: "rgba(255,204,0,0.2)",
  },
  LightenDarkenColor: (color: string, percent: number): string => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const B = ((num >> 8) & 0x00ff) + amt;
    const G = (num & 0x0000ff) + amt;
    return (
      // eslint-disable-next-line prefer-template
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  },
};

export default colors;
