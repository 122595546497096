/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect, useState } from "react";
import { Layout, Card } from "antd";
import { Redirect, Route, Router, Switch, Link } from "react-router-dom";
import qs from "querystring";
import "./styles/app.less";
import history from "src/utils/history";
import appStyle from "./styles/app";
import PaymentRailsFrame from "../components/payment-rails-frame";
import MissingInfo from "../components/missing-info";
import AppHeader from "./appHeader";
import AppFooter from "./appFooter";
import Sidebar from "../components/sidebar";

function App(): ReactElement {
  const { Content } = Layout;

  const [paymentAuthString, setAuthString] = useState("");

  useEffect(() => {
    const hash = qs.parse(window.location.hash.substring(1));
    if (hash && hash.token) {
      if (Array.isArray(hash.token)) {
        setAuthString(hash.token[0]);
      } else {
        setAuthString(hash.token);
      }
      window.history.replaceState(
        {},
        window.document.title,
        window.location.pathname + window.location.search
      );
    }
  }, []);

  const hasAuth =
    paymentAuthString || window.location.hash.indexOf("token=") !== -1;
  let mainContent = <Redirect to="/missing-info" />;
  if (hasAuth) {
    mainContent = <PaymentRailsFrame authString={paymentAuthString} />;
  }

  return (
    <div>
      <Router history={history}>
        <Layout css={appStyle.layout}>
          <AppHeader />
          <Content css={appStyle.body}>
            <Switch>
              <Route exact path="/index.html">
                <Redirect to="/" />
              </Route>
              <Route exact path="/">
                {mainContent}
              </Route>
              <Route exact path="/missing-info">
                <MissingInfo />
              </Route>
            </Switch>
            <AppFooter />
          </Content>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
