import { css } from "@emotion/react";
import colors from "../../colors";

const appStyle = {
  userHeader: {
    wrapper: css`
      background: ${colors.white};
      padding: 15px;
      border-bottom: solid 2px #dcdcdc;
    `,
    infoWrapper: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `,
    userName: css`
      font-size: 18px;
      font-weight: 300;
      margin-bottom: -4.5px;
      color: #000;
      width: 173px;
    `,
    userInfo: css`
      font-size: 11.5px;
      font-weight: 300;
      margin-bottom: 4.5px;
    `,
    proBadge: css`
      margin-top: -14px;
      margin-right: -1px;
      font-size: 10.5px;
      background-color: #ca4319;
      line-height: 1;
      color: #fff;
      padding: 2px 3px;
      border-radius: 2px;
      top: 58px;
      right: 0;
      position: absolute;
      cursor: pointer;
      font-weight: 400;
    `,
  },
  bodyHeader: {
    wrapper: css`
      padding: 0;
      height: 57px;
      background: ${colors.gray.full};
      position: fixed;
      z-index: 100;
      width: 100%;
      left: 0;
    `,
    menuRowRight: css`
      text-align: right;
      padding-right: 24px;
      line-height: 57px;
      color: ${colors.white};
    `,

    logo: css`
      height: 57px;
      padding-left: 0;
      margin-right: -5px;
      img {
        margin-left: -4px;
        width: 168.5px;
        align-self: center;
        vertical-align: top;
      }
    `,
  },
  layout: css`
    background: ${colors.white};
    min-height: 100vh;
  `,
  bodyLayout: css`
    background: ${colors.white};
  `,
  body: css`
    margin: 73px auto 15px;
    > div {
      min-height: calc(100vh - 128px);
    }
  `,

  menuHeader: css`
    background: ${colors.gray.full};
    height: 57px;
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    margin-right: -5px;
    img {
      margin-left: -4px;
      width: 168.5px;
      align-self: center;
    }
  `,
  leftLayout: css`
    background: #d8d8d8;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.13), 0 2px 3px rgba(0, 0, 0, 0.22);
    position: fixed;
    height: 100%;
    z-index: 101;
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
  `,
  leftMenu: {
    wrapper: css`
      box-shadow: -1px 0 0 1px rgba(0, 0, 0, 0.13),
        0 2px 3px rgba(0, 0, 0, 0.22);
      min-height: 50vh;
      background: #f8f8f8;
    `,
    menuItem: css`
      background: white;
      line-height: 43px !important;
      height: 43px !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      padding-left: 16px !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      font-weight: 100;
      font-size: 15px;
      color: #495057;
      svg {
        font-size: 15px;
      }

      &.ant-menu-item-selected {
        background: #41536e !important;
        color: #fff !important;
        border-right: none !important;
        ::after {
          display: none !important;
        }
      }
      :hover {
        background: ${colors.gray.f5};
        color: #495057;
        a {
          color: #495057;
        }
      }
    `,
    menuIcon: css`
      margin-right: 20px !important;
      display: inline-block;
      min-width: 20px !important;
      text-align: center;
    `,
    menuText: css`
      display: inline-block;
      margin-left: -7px;
    `,
    bottomSection: {
      wrapper: css`
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        display: flex;
        > div {
          display: flex;
          flex-direction: column;
        }
      `,
      view: css`
        height: 30px;
        background: #cecece;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: solid 1px #b3b3b3;
        font-size: 13.25px;
        text-transform: uppercase;

        .__sw {
          cursor: pointer;
          position: relative;
          width: 40px;
          background-color: #343a40;
          transition: 0.4s;
          border-radius: 1px;
          height: 15px;
          margin-bottom: -1px;
          display: flex;
          justify-content: flex-end;
          span {
            display: block;
            position: absolute;
            height: 9px;
            width: 9px;
            right: 3px;
            bottom: 3px;
            background-color: #fff;
            transition: 0.4s;
          }
        }
      `,
      buttons: css`
        display: flex;
        justify-content: center;
        padding: 12px 0 3px;
        background: #efefef;
      `,
      button: css`
        width: 90px;
        font-size: 12.5px;
        border-radius: 0.2rem;
        padding: 0.25rem 0.3rem !important;
        height: auto !important;

        svg {
          margin-right: 9px;
          font-size: 11.5px;
        }
      `,
      info: css`
        font-size: 12.2px;
        font-weight: 400;
        color: #101010;
        background: #efefef;
        text-align: center;
        padding: 5px 0 7px;
      `,
    },
  },
  footer: {
    wrapper: css`
      margin: 15px -15px -15px -15px;
      background: ${colors.white};
      text-align: center;
      min-height: 180px;
    `,
    socialIcons: css`
      justify-content: center;
      height: 100px;

      > div {
        width: 122px;
        text-align: center;
      }
    `,
    socialIcon: css`
      display: block;
      > div {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-size: 700%;
        background-image: url("https://d2wbsh8jsobsq.cloudfront.net/general/social-footer-icons_d2020.png");
        filter: brightness(100%);
        :hover {
          filter: brightness(87%);
          transition: all 0.2s ease;
        }
      }
    `,
    footerLinks: css`
      border-bottom: solid 1px rgba(221, 221, 221, 0.5);
      justify-content: space-between;
      align-items: flex-start;
      height: 36px;

      a {
        color: #6d6b6b;
        font-size: 12.5px;
      }

      a:hover {
        color: #333;
      }
    `,
    copyright: css`
      margin-top: 10px;
      color: #6d6b6b;
      font-size: 12px;
      text-align: center;
      margin-bottom: 30px;
    `,
  },
};

export default appStyle;
