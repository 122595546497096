import React, { ReactElement } from "react";
import { Spin } from "antd";
import styles from "./styles";

const PageLoading = (): ReactElement => {
  return (
    <div className="page-loading" css={styles.loading}>
      <Spin />
    </div>
  );
};

export default PageLoading;
