export const breakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200,
  xxLarge: 2000,
} as { [key: string]: number };

export const queries = Object.keys(breakpoints).reduce(
  (mapping, size) => ({
    ...mapping,
    [size]: `(min-width: ${breakpoints[size]}px)`,
  }),
  {}
) as IQueries;

interface IQueries {
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
  xxLarge: string;
}
