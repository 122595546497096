import React, { ReactElement } from "react";

const MissingInfo = (): ReactElement => {
  return (
    <p>
      No login is available. Visit{" "}
      <a href="https://songtradr.com">songtradr.com</a> for more details.
    </p>
  );
};

export default MissingInfo;
