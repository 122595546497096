import React, { ReactElement, useCallback, useEffect, useState } from "react";
import PageLoading from "../page-loading";
import styles from "./styles";

interface IPaymentRailsMessage {
  event?: string;
  data?: string;
}

interface IProps {
  authString: string;
}

const PaymentRailsFrame = ({ authString }: IProps): ReactElement => {
  const [height, setHeight] = useState(500);

  const heightHandler = useCallback(
    (h: number) => {
      setHeight(h);
    },
    [setHeight]
  );

  useEffect(() => {
    function eventListener(event: MessageEvent<IPaymentRailsMessage>) {
      if (event.data && event.data.event === "document.height") {
        heightHandler(parseInt(event.data.data || "0", 10));
      }
    }

    window.addEventListener("message", eventListener);
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, [heightHandler]);

  if (!authString) {
    return <PageLoading />;
  }

  return (
    <iframe
      title="Payment Details"
      src={`https://widget.paymentrails.com?${authString}`}
      width="100%"
      css={styles.frame}
      height={`${height}px`}
      id="payment-rails-widget"
    />
  );
};

export default PaymentRailsFrame;
